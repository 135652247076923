import React, {
  createContext,
  useEffect,
  useState,
  useMemo,
  ReactNode,
} from 'react';
import Cookies from 'js-cookie';
import getCookieDomain from '@rsc/utils/getCookieDomain';
import { LocaleCurrencyEnum } from '@rsc/models/LocaleCurrencyEnum';

const CURRENCY_COOKIE = 'currency';
const COUNTRY_CONFIRMED_COOKIE = 'country_confirmed';

export type LocaleContextType = {
  currency: LocaleCurrencyEnum;
  updateCurrency: (newCurrency: LocaleCurrencyEnum) => void;
  getCountryCode: () => string;
  countryPickerActive: boolean;
  setCountryPickerActive: React.Dispatch<React.SetStateAction<boolean>>;
  setCountryCookie: () => void;
};
// create locale context to store currency
const LocaleContext = createContext<LocaleContextType | undefined>(undefined);

const isBrowser = typeof window !== 'undefined';

const getActiveCurrency = (
  allowedCurrencies: LocaleCurrencyEnum[]
): LocaleCurrencyEnum => {
  if (isBrowser) {
    const urlParams = new URLSearchParams(window.location.search);
    const queryCurrency = urlParams.get(CURRENCY_COOKIE);
    const storedCurrency = Cookies.get(CURRENCY_COOKIE);

    if (
      queryCurrency &&
      allowedCurrencies.includes(
        queryCurrency.toUpperCase() as LocaleCurrencyEnum
      )
    ) {
      return queryCurrency.toUpperCase() as LocaleCurrencyEnum;
    }

    if (storedCurrency) {
      // make sure stored currency is allowed
      const initialCurrency = allowedCurrencies.includes(
        storedCurrency?.toUpperCase() as LocaleCurrencyEnum
      )
        ? storedCurrency.toUpperCase()
        : LocaleCurrencyEnum.USD;

      return initialCurrency as LocaleCurrencyEnum;
    }
  }

  return LocaleCurrencyEnum.USD;
};

const hasCountryCookie = () => {
  if (isBrowser) {
    const currency = Cookies.get(CURRENCY_COOKIE);

    if (currency === LocaleCurrencyEnum.USD) {
      return true;
    }

    const countryConfirmed = Cookies.get(COUNTRY_CONFIRMED_COOKIE);

    return countryConfirmed === 'true';
  }

  return false;
};

const setCountryCookie = () => {
  if (isBrowser) {
    Cookies.set(COUNTRY_CONFIRMED_COOKIE, 'true', {
      expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000),
      domain: getCookieDomain(),
    });
  }
};

export type LocaleProviderProps = {
  children: ReactNode;
  allowedCurrencies: LocaleCurrencyEnum[];
};
// create locale provider to store currency
function LocaleProvider({
  children,
  allowedCurrencies,
}: Readonly<LocaleProviderProps>) {
  // set default currency
  const [currency, setCurrency] = useState<LocaleCurrencyEnum>(
    getActiveCurrency(allowedCurrencies)
  );

  const [countryPickerActive, setCountryPickerActive] = useState(false);

  useEffect(() => {
    if (!hasCountryCookie()) {
      setCountryPickerActive(true);
    }
  }, []);

  const updateCurrency: LocaleContextType['updateCurrency'] = newCurrency => {
    if (
      allowedCurrencies.includes(
        newCurrency?.toUpperCase() as LocaleCurrencyEnum
      )
    ) {
      setCurrency(newCurrency.toUpperCase() as LocaleCurrencyEnum);
    }
  };

  const getCountryCode: LocaleContextType['getCountryCode'] = () => {
    let countryCode = 'US';

    if (currency === LocaleCurrencyEnum.CAD) {
      countryCode = 'CA';
    }

    return countryCode;
  };

  useEffect(() => {
    setCurrency(getActiveCurrency(allowedCurrencies));
  }, []);

  // write currency to browser storage
  useEffect(() => {
    Cookies.set(CURRENCY_COOKIE, currency, {
      expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000),
      domain: getCookieDomain(),
    });
  }, [currency]);

  const memoizedValue = useMemo(
    () => ({
      currency,
      updateCurrency,
      getCountryCode,
      countryPickerActive,
      setCountryPickerActive,
      setCountryCookie,
    }),
    [currency, countryPickerActive]
  );

  // return locale context provider
  return (
    <LocaleContext.Provider value={memoizedValue}>
      {children}
    </LocaleContext.Provider>
  );
}

export { LocaleProvider, LocaleContext };
