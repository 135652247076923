const slugify = require('slugify');

// both productHandle and productType are strings.
// This file is used by both es6 modules and commonJS on build, so can't convert to .ts
const generateProductSlug = ({ productHandle, productType }) => {
  if (!productHandle) {
    console.error('Product handle not supplied to generateProductSlug');
    return null;
  }

  const prefix = process.env.GATSBY_PRODUCT_SLUG_PREFIX;

  let slugRoot = '';
  if (prefix) slugRoot = `${prefix}/`;
  else if (productType)
    slugRoot = `${slugify(productType, { strict: true, lower: true })}/`;

  const slug = `${slugRoot}${productHandle}`;

  return slug;
};

module.exports = generateProductSlug;
