import React, { createContext, useState, useMemo, ReactNode } from 'react';
import { AppSiteSettings } from '@rsc/models/AppSanityTypes';

export type SiteSettingsContextType = {
  siteSettings: AppSiteSettings;
  updateSiteSettings: (newSiteSettings: AppSiteSettings) => void;
};

const SiteSettingsContext = createContext<SiteSettingsContextType | undefined>(
  undefined
);

export type SiteSettingsProviderProps = {
  children: ReactNode;
  useSiteSettings: () => AppSiteSettings;
};

function SiteSettingsProvider({
  children,
  useSiteSettings,
}: Readonly<SiteSettingsProviderProps>) {
  const defaultSiteSettings = useSiteSettings();
  const [currentSiteSettings, setCurrentSiteSettings] =
    useState(defaultSiteSettings);

  const updateSiteSettings = (newSiteSettings: AppSiteSettings) => {
    setCurrentSiteSettings(newSiteSettings);
  };

  const memoizedValue: SiteSettingsContextType = useMemo(
    () => ({
      siteSettings: currentSiteSettings,
      updateSiteSettings,
    }),
    [currentSiteSettings]
  );

  return (
    <SiteSettingsContext.Provider value={memoizedValue}>
      {children}
    </SiteSettingsContext.Provider>
  );
}

export { SiteSettingsProvider, SiteSettingsContext };
