import aa from 'search-insights';
import { v4 as uuidv4 } from 'uuid';

const isBrowser = typeof window !== 'undefined';

const InitInsights = () => {
  aa('init', {
    appId: process.env.GATSBY_ALGOLIA_APP_ID,
    apiKey: process.env.GATSBY_ALGOLIA_SEARCH_KEY,
  });

  if (isBrowser) {
    let uuid = window.localStorage.getItem('uuid');
    if (!uuid) {
      uuid = uuidv4();
      window.localStorage.setItem('uuid', uuid);
    }

    aa('setUserToken', uuid);

    aa(
      'onUserTokenChange',
      userToken => {
        window.dataLayer?.push({
          algoliaUserToken: userToken,
        });
      },
      { immediate: true }
    );
  }
};

const TrackSearchView = (index, objectIDs, queryID) => {
  if (isBrowser) {
    aa('viewedObjectIDs', {
      userToken: window.localStorage.getItem('uuid'),
      index,
      eventName: 'Search Results Viewed',
      queryID,
      objectIDs,
    });
  }
};

const TrackClickAfterSearch = insights => {
  if (typeof insights === 'function') {
    insights('clickedObjectIDsAfterSearch', {
      eventName: 'Search Result Clicked',
    });
  }
};

const TrackConversionAfterSearch = (queryID, objectIDs) => {
  if (isBrowser) {
    aa('convertedObjectIDsAfterSearch', {
      userToken: window.localStorage.getItem('uuid'),
      index: 'products',
      eventName: 'Searched Product Added To Cart',
      queryID,
      objectIDs,
    });
  }
};

export {
  InitInsights,
  TrackSearchView,
  TrackConversionAfterSearch,
  TrackClickAfterSearch,
  aa,
};
