import { GetInternalLinkFunctionType } from '@rsc/models/GetInternalLinkFunctionType';
import { LocaleCurrencyEnum } from '@rsc/models/LocaleCurrencyEnum';

const getTranslatedSlug = (slug, currency, anchor) => {
  let localeSlug = slug;

  if (currency === LocaleCurrencyEnum.CAD) {
    localeSlug = `/en-ca${localeSlug}`;
  }

  // Check if Anchor exists and add it to the url
  if (anchor) {
    localeSlug = `${localeSlug}#${anchor}`;
  }

  return localeSlug;
};

const getInternalLink: GetInternalLinkFunctionType = ({
  slug,
  currency = LocaleCurrencyEnum.USD,
  anchor,
}) => {
  if (slug) {
    return getTranslatedSlug(slug, currency, anchor);
  }
  return slug;
};

export default getInternalLink;
